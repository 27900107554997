// eslint-disable-next-line import/no-unresolved
const React = require('react');
const restclient = require('nordic/restclient');
const { SearchBar: SearchBarAI } = require('dx-virtual-assistent-sdk/lib/ui');
const { getEventTracker } = require('../../../utils/event-tracker');

const httpClient = restclient({
  baseURL: '',
  timeout: 30000,
});

const SearchBar = ({ lang, isMobile, siteId, layout, portal, maxBoxVH }) => {
  const Tracker = getEventTracker();
  if (typeof window === typeof undefined) {
    return null;
  }

  const TRACKER_BASE_NAME = 'mpdevelopers/search';

  return (
    <SearchBarAI
      lang={lang}
      isMobile={isMobile}
      siteId={siteId}
      layout={layout}
      portal={portal}
      HTTPClient={httpClient}
      maxBoxVH={maxBoxVH}
      endpoints={{
        ask: '/developers/api/search-llm/ask',
        feedback: '/developers/api/search-llm/feedback',
        references: `/developers/api/search/references/${lang}`,
        searchdocs: `/developers/api/search/guides/${lang}`,
      }}
      onFocusInput={(e) => Tracker.send(`${TRACKER_BASE_NAME}/focus`, e)}
      onSearchStart={(e) => Tracker.send(`${TRACKER_BASE_NAME}/start`, e)}
      onFeedback={(e) => Tracker.send(`${TRACKER_BASE_NAME}/feedback/${e ? 'positive' : 'negative'}`, e)}
      onCopyToClipboard={(e) => Tracker.send('dx/search/ai-answer/copy', e)}
      onLoadUserHistory={(e) => Tracker.send(`${TRACKER_BASE_NAME}/loaded_user_history`, e)}
      onSuccess={() => Tracker.send(`${TRACKER_BASE_NAME}/success`)}
      onAiAnswer={(e) => Tracker.send(`${TRACKER_BASE_NAME}/ai_answer`, e)}
      onClassicAnswer={(e) => Tracker.send(`${TRACKER_BASE_NAME}/classic_answer`, e)}
      onError={(e) => Tracker.send(`${TRACKER_BASE_NAME}/error`, e)}
      onDone={(e) => Tracker.send(`${TRACKER_BASE_NAME}/done`, e)}
      onClickSuggestion={(e) => Tracker.send(`${TRACKER_BASE_NAME}/suggestion-click`, e)}
      onSearchToggle={(e) => Tracker.send(`${TRACKER_BASE_NAME}/toggle/${e ? 'open' : 'close'}`)}
    />
  );
};

module.exports = SearchBar;
