const React = require('react');

function SecurityImage() {
  return (
    <svg width="65" height="81" viewBox="0 0 65 81" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M1.56783 12.9919C1.3839 13.0598 1.23383 13.1786 1.12906 13.3284C1.02418 13.4783 0.964667 13.6592 0.965244 13.8501L0.965257 39.4441C0.9229 48.865 4.14188 57.6893 9.68372 64.7253C15.2243 71.7598 23.0868 77.0069 32.7729 79.2752C42.0168 77.0069 49.8792 71.7598 55.4199 64.7253C60.9617 57.6893 64.1807 48.865 64.1383 39.4441V13.8587C64.1389 13.6592 64.0794 13.4783 63.9745 13.3284C63.8698 13.1786 63.7197 13.0598 63.539 12.9931L32.9048 1.63592C32.6757 1.56016 32.4279 1.56016 32.1988 1.63592L1.56783 12.9919Z" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M1.70004 12.9594C1.51689 13.0274 1.36746 13.1464 1.26313 13.2963C1.1587 13.4464 1.09943 13.6276 1.10001 13.8186L1.10002 39.4428C1.05784 48.8748 4.26318 57.7094 9.78155 64.7538C15.2987 71.7966 23.1279 77.0498 32.7729 79.3207C41.9777 77.0498 49.8068 71.7966 55.324 64.7538C60.8423 57.7094 64.0477 48.8748 64.0055 39.4428V13.8272C64.0061 13.6276 63.9468 13.4464 63.8424 13.2963C63.7381 13.1464 63.5886 13.0274 63.4087 12.9606L32.9043 1.59009C32.6762 1.51424 32.4294 1.51424 32.2012 1.59009L1.70004 12.9594Z" className="dx-devsite-home-images-brand-color" />
      <path fillRule="evenodd" clipRule="evenodd" d="M4.10002 39.4562C4.06096 48.192 7.02818 56.3743 12.1432 62.9037C17.1129 69.2477 24.1102 74.031 32.7557 76.2281C40.9892 74.0328 47.9889 69.2525 52.9623 62.9037C58.0773 56.3743 61.0446 48.192 61.0055 39.4562L61.0055 39.4428L61.0055 15.2665L32.5528 4.66069L4.10001 15.2665L4.10002 39.4562ZM32.9043 1.59009L63.4087 12.9606C63.5886 13.0274 63.7381 13.1464 63.8424 13.2963C63.9468 13.4464 64.0061 13.6276 64.0055 13.8272V39.4428C64.0477 48.8748 60.8423 57.7094 55.324 64.7538C49.8068 71.7966 41.9777 77.0498 32.7729 79.3207C23.1279 77.0498 15.2987 71.7966 9.78155 64.7538C4.26318 57.7094 1.05784 48.8748 1.10002 39.4428L1.10001 13.8186C1.09943 13.6276 1.1587 13.4464 1.26313 13.2963C1.36746 13.1464 1.51689 13.0274 1.70004 12.9594L32.2012 1.59009C32.4294 1.51424 32.6762 1.51424 32.9043 1.59009ZM61.0055 13.8184V13.8272Z" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M1.58676 12.9594C1.4036 13.0274 1.25418 13.1464 1.14984 13.2963C1.04541 13.4464 0.986152 13.6276 0.986726 13.8186L0.986739 39.4428C0.944562 48.8748 4.1499 57.7094 9.66827 64.7538C15.1854 71.7966 23.0146 77.0498 32.6597 79.3207C41.8644 77.0498 49.6935 71.7966 55.2107 64.7538C60.729 57.7094 63.9344 48.8748 63.8922 39.4428V13.8272C63.8928 13.6276 63.8335 13.4464 63.7291 13.2963C63.6248 13.1464 63.4753 13.0274 63.2955 12.9606L32.791 1.59009C32.5629 1.51424 32.3161 1.51424 32.0879 1.59009L1.58676 12.9594Z" stroke="#333333" strokeWidth="1.5" />
      <ellipse cx="32.4392" cy="33.458" rx="16.2712" ry="16.25" fill="white" stroke="#333333" strokeWidth="1.5" />
    </svg>
  );
}

module.exports = SecurityImage;
