const React = require('react');
const Style = require('nordic/style');

const RebrandingStyle = ({ brand }) => {
  const mpBrand = `
    body {
      --andes-color-blue-100: rgba(71, 154, 209, 0.1);
      --andes-color-blue-150: rgba(71, 154, 209, 0.15);
      --andes-color-blue-200: rgba(71, 154, 209, 0.2);
      --andes-color-blue-300: rgba(71, 154, 209, 0.3);
      --andes-color-blue-400: rgba(71, 154, 209, 0.4);
      --andes-color-blue-500: #009ee3;
      --andes-color-blue-600: #007eb5;
      --andes-color-blue-700: #005e88;
      --andes-color-blue-800: #004766;
      --andes-color-fill-brand: var(--andes-color-blue-500);
      --andes-color-text-brand: #fff;
    }
  `;

  const mlBrand = `
    body {
      --andes-color-yellow-500: #ffe600;
      --andes-color-blue-100: rgba(65, 137, 230, 0.1);
      --andes-color-blue-150: rgba(65, 137, 230, 0.15);
      --andes-color-blue-200: rgba(65, 137, 230, 0.2);
      --andes-color-blue-300: rgba(65, 137, 230, 0.3);
      --andes-color-blue-400: rgba(65, 137, 230, 0.4);
      --andes-color-blue-500: #3483fa;
      --andes-color-blue-600: #2968c8;
      --andes-color-blue-700: #1f4e96;
      --andes-color-blue-800: #183c73;
      --andes-color-fill-brand: var(--andes-color-yellow-500);
      --andes-color-text-brand: rgba(0, 0, 0, 0.9);
    }
  `;

  const activeBrand = brand === 'yellowblue-light' ? mlBrand : mpBrand;

  return (
    <Style>
      {activeBrand}
    </Style>
  );
};

module.exports = RebrandingStyle;
