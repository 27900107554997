/* eslint-disable security/detect-object-injection */
/* eslint-disable react/no-array-index-key */
const React = require('react');
const moment = require('moment');
const HomeCarousel = require('../HomeCarousel');

const { CarouselItem } = HomeCarousel;

const HomeBanners = ({ lang, isMobile, banners, siteId, brand }) => {
  const dateAux = Date.now();
  const dateFormat = moment(dateAux).format('YYYY/MM/DD h:mm:ss');
  const bannersBySite = banners?.filter(n => n.sites_enabled?.includes(siteId.toLowerCase()));
  const bannersActives = bannersBySite?.filter(n => n.is_active && (n.expiration_date > dateFormat || n.expiration_date === dateAux)
  && dateFormat > n.publication_date);

  return (
    <div className="home-rebranding-section">
      {bannersActives && bannersActives?.length > 0 && (
        <HomeCarousel>
          {bannersActives?.map((banner, index) => (
            <CarouselItem
              key={index}
              index={index}
              title={banner.content.title[lang]}
              subtitle={banner.content.subtitle[lang]}
              lang={lang}
              buttons={[
                {
                  text: banner.content?.cta1?.[lang],
                  url: banner?.content?.cta_link1?.[lang],
                  tracking: banner?.content?.tracking_cta1,
                },
                {
                  text: banner?.content?.cta2?.[lang],
                  url: banner?.content?.cta_link2?.[lang],
                  tracking: banner?.content?.tracking_cta2,
                },
              ]}
              SlideImage={banner?.content?.image_url}
              isMobile={isMobile}
              background={banner?.content?.background}
              brand={brand}
            />
          ))}
        </HomeCarousel>
      )}
    </div>
  );
};


module.exports = HomeBanners;
