const React = require('react');

function OnlinePaymentImage() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 102 75"
    >
      <path
        fill="white"
        stroke="white"
        strokeWidth="3"
        d="M20.6238 8.26953H99.0635V53.3873C99.0635 55.8726 97.0488 57.8873 94.5635 57.8873H25.1238C22.6385 57.8873 20.6238 55.8726 20.6238 53.3873V8.26953Z"
      />
      <path
        stroke="#333333"
        strokeWidth="1.5"
        d="M19.1238 6.76953H100.564V53.3873C100.564 56.701 97.8772 59.3873 94.5635 59.3873H25.1238C21.8101 59.3873 19.1238 56.701 19.1238 53.3873V6.76953Z"
      />
      <path
        fill="white"
        stroke="#333333"
        strokeWidth="1.5"
        d="M19.1235 4.90507C19.1235 3.15179 20.5449 1.73047 22.2981 1.73047H97.4033C99.1566 1.73047 100.578 3.15179 100.578 4.90507V6.93742H19.1235V4.90507Z"
      />
      <ellipse
        cx="24.5468"
        cy="4.48115"
        rx="0.793623"
        ry="0.793651"
        fill="#333333"
      />
      <ellipse
        cx="28.1071"
        cy="4.48115"
        rx="0.793623"
        ry="0.793651"
        fill="#333333"
      />
      <ellipse
        cx="31.7984"
        cy="4.48115"
        rx="0.793623"
        ry="0.793651"
        fill="#333333"
      />
      <rect
        width="27.6607"
        height="27.9891"
        transform="matrix(-1 0 0 1 87.7209 18.1094)"
        className="dx-devsite-home-images-brand-color"
      />
      <rect
        width="27.6607"
        height="27.9891"
        transform="matrix(-1 0 0 1 87.7209 18.6523)"
        stroke="#333333"
        strokeWidth="1.5"
      />
      <path
        stroke="#333333"
        strokeWidth="1.5"
        d="M85.675 26.3762V20.9414H81.6951M81.6951 42.7902H85.675V37.3555"
      />
      <path
        stroke="#333333"
        strokeWidth="1.5"
        d="M60.0605 10.5V55.0652"
      />
      <path
        stroke="#333333"
        strokeWidth="1.5"
        d="M73.8033 28.0906C73.4427 26.9875 72.6293 26.3086 71.2055 26.3086C69.7141 26.3086 68.6059 27.4436 68.6059 29.1072C68.6059 31.1692 69.5098 31.5869 70.8825 32.079C70.9691 32.1109 71.0555 32.1428 71.1414 32.1749L71.3123 32.2396C72.4171 32.6645 73.8033 33.0761 73.9253 34.7308C74.0473 36.3855 73.0757 37.8598 71.1064 37.8537C69.7141 37.8493 68.5379 36.9651 68.3269 35.5688"
      />
      <path
        stroke="#333333"
        strokeWidth="1.5"
        d="M71.2477 24.2695V26.3061M71.2476 37.9922V40.0288"
      />
      <path
        fill="white"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.5484 58.2564V17.6782C45.5484 15.7572 43.9541 14.1992 41.9888 14.1992H15.8631C13.8973 14.1992 12.303 15.7572 12.303 17.6782V38.9659V59.4152V70.0095C12.303 72.2796 14.1862 74.1199 16.5092 74.1199H41.3421C43.6652 74.1199 45.5484 72.2796 45.5484 70.0095V64.21V58.2564Z"
      />
      <path
        fill="white"
        stroke="white"
        strokeWidth="3"
        d="M44.0075 22.3477V65.181H13.7748V22.3477H44.0075Z"
      />
      <path
        fill="#EEEEEE"
        fillRule="evenodd"
        clipRule="evenodd"
        style={{ mixBlendMode: 'multiply' }}
        d="M41.156 72.196H16.4822C14.1741 72.196 12.303 70.4101 12.303 68.207V70.1294C12.303 72.3325 14.1741 74.1185 16.4822 74.1185H41.156C43.4641 74.1185 45.3353 72.3325 45.3353 70.1294V68.207C45.3353 70.4101 43.4641 72.196 41.156 72.196Z"
      />
      <path
        stroke="#333333"
        strokeWidth="1.5"
        d="M45.5202 58.2564V17.6782C45.5202 15.7572 43.9259 14.1992 41.9606 14.1992H15.8349C13.8691 14.1992 12.2748 15.7572 12.2748 17.6782V38.9659V59.4152V70.0095C12.2748 72.2796 14.158 74.1199 16.481 74.1199H41.3139C43.637 74.1199 45.5202 72.2796 45.5202 70.0095V64.21V58.2564Z"
      />
      <path
        stroke="#333333"
        strokeWidth="1.5"
        d="M12.303 66.6812H45.3353M45.3353 20.7867H12.303"
      />
      <path
        stroke="#333333"
        strokeWidth="1.5"
        d="M27.0874 17.8101H31.9876M25.1237 17.8101H26.0082M26.7946 70.0836H31.0568"
      />
      <rect
        x="1.32983"
        y="32.8477"
        width="27.6607"
        height="20.4365"
        className="dx-devsite-home-images-brand-color"
      />
      <rect
        x="1.32983"
        y="33.2461"
        width="27.6607"
        height="20.4365"
        stroke="#333333"
        strokeWidth="1.5"
      />
      <path
        stroke="#333333"
        strokeWidth="1.5"
        d="M3.37598 38.8862V34.918H7.35594M7.35594 50.8706H3.37598V46.9023"
      />
      <path
        stroke="#333333"
        strokeWidth="1.5"
        d="M28.9906 27.293V59.8327"
      />
      <path
        stroke="#333333"
        strokeWidth="1.5"
        d="M23.1332 40.059C22.7726 39.2535 21.9593 38.7578 20.5355 38.7578C19.044 38.7578 17.9359 39.5865 17.9359 40.8012C17.9359 42.3068 18.8398 42.6118 20.2125 42.9711C20.2991 42.9944 20.3855 43.0177 20.4714 43.0411L20.6423 43.0884C21.7471 43.3986 23.1332 43.6991 23.2552 44.9073C23.3772 46.1156 22.4056 47.192 20.4364 47.1875C19.044 47.1844 17.8678 46.5388 17.6569 45.5192"
      />
      <path
        stroke="#333333"
        strokeWidth="1.5"
        d="M20.5775 37.2695V38.7566M20.5775 47.2891V48.7761"
      />
    </svg>
  );
}

module.exports = OnlinePaymentImage;
