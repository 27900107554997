const React = require('react');

function FaceToFacePaymentImage() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 89 66"
    >
      <rect
        x="12.917"
        y="22.7695"
        width="69.283"
        height="40.7547"
        fill="white"
        stroke="#333333"
        strokeWidth="1.5"
      />
      <rect
        x="25.2366"
        y="36.5312"
        width="23.4419"
        height="16.5775"
        rx="1"
        fill="white"
        stroke="#333333"
        strokeWidth="1.5"
      />
      <path
        d="M41.8322 47.0378L45.8717 43.4198"
        stroke="#333333"
        strokeWidth="1.5"
      />
      <path
        d="M28.321 45.9792L32.3606 42.3612"
        stroke="#333333"
        strokeWidth="1.5"
      />
      <path
        d="M40.8975 44.3152L43.3344 42.2771"
        stroke="#333333"
        strokeWidth="1.5"
      />
      <path
        d="M30.4473 47.8659L32.8842 45.8279"
        stroke="#333333"
        strokeWidth="1.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.9575 53.1088V36.5312V53.1088Z"
        fill="white"
      />
      <path
        d="M36.9575 53.1088V36.5312"
        stroke="#333333"
        strokeWidth="1.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        className="dx-devsite-home-images-brand-color"
        d="M14.9617 27.8356C19.3613 27.8356 22.9279 25.0682 22.9279 21.6545L23.0632 13.7461H7.13085L6.99551 21.6545C6.99551 25.0682 10.5621 27.8356 14.9617 27.8356ZM57.8117 34.9394C57.8117 34.3871 58.2594 33.9394 58.8117 33.9394H76.3466C76.8989 33.9394 77.3466 34.3871 77.3466 34.9394V63.5221H57.8117V34.9394ZM16.4653 43.1447L19.2342 58.8962H7.8106L4.97546 43.1447H16.4653ZM55.0632 21.6545C55.0632 25.0682 51.4966 27.8356 47.097 27.8356C42.6974 27.8356 39.1309 25.0682 39.1309 21.6545L39.2662 13.7461H55.1986L55.0632 21.6545ZM79.2324 27.8356C83.632 27.8356 87.1986 25.0682 87.1986 21.6545L87.3339 13.7461H71.4016L71.2662 21.6545C71.2662 25.0682 74.8328 27.8356 79.2324 27.8356Z"
      />
      <path
        stroke="#333333"
        strokeWidth="1.5"
        d="M57.8116 35.9375C57.8116 34.8329 58.7071 33.9375 59.8116 33.9375H75.3465C76.4511 33.9375 77.3465 34.8329 77.3465 35.9375V63.5201H57.8116V35.9375Z"
      />
      <circle
        cx="67.5792"
        cy="42.1754"
        r="2.87071"
        fill="white"
        stroke="#333333"
        strokeWidth="1.5"
      />
      <path
        d="M0.917028 63.4473L4.42309 43.1445H15.913"
        stroke="#333333"
        strokeWidth="1.5"
      />
      <path
        d="M19.9714 63.4473L16.4653 43.1445H4.97546L8.48153 63.4473"
        stroke="#333333"
        strokeWidth="1.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.2342 58.896L16.4653 43.1445H4.97546L7.74429 58.896H19.2342Z"
        stroke="#333333"
        strokeWidth="1.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.9956 21.6545C38.9956 25.0682 35.429 27.8356 31.0294 27.8356C26.6298 27.8356 23.0632 25.0682 23.0632 21.6545L23.1986 13.7461H39.1309L38.9956 21.6545Z"
        fill="white"
        stroke="#333333"
        strokeWidth="1.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M71.131 21.6545C71.131 25.0682 67.5644 27.8356 63.1648 27.8356C58.7652 27.8356 55.1986 25.0682 55.1986 21.6545L55.3339 13.7461H71.2663L71.131 21.6545Z"
        fill="white"
        stroke="#333333"
        strokeWidth="1.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.9279 21.6545C22.9279 25.0682 19.3614 27.8356 14.9618 27.8356C10.5622 27.8356 6.99561 25.0682 6.99561 21.6545C6.99561 21.6545 7.13094 13.7461 7.13094 13.7461C7.13094 13.7461 23.0633 13.7461 23.0633 13.7461C23.0633 13.7461 22.9279 21.6545 22.9279 21.6545Z"
        stroke="#333333"
        strokeWidth="1.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.0633 21.6545C55.0633 25.0682 51.4967 27.8356 47.0972 27.8356C42.6976 27.8356 39.131 25.0682 39.131 21.6545C39.131 21.6545 39.2663 13.7461 39.2663 13.7461C39.2663 13.7461 55.1987 13.7461 55.1987 13.7461C55.1987 13.7461 55.0633 21.6545 55.0633 21.6545Z"
        stroke="#333333"
        strokeWidth="1.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M87.1987 21.6545C87.1987 25.0682 83.6321 27.8356 79.2325 27.8356C74.8329 27.8356 71.2664 25.0682 71.2664 21.6545C71.2664 21.6545 71.4017 13.7461 71.4017 13.7461C71.4017 13.7461 87.334 13.7461 87.334 13.7461C87.334 13.7461 87.1987 21.6545 87.1987 21.6545Z"
        stroke="#333333"
        strokeWidth="1.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.92256 13.5083C6.92898 13.4999 6.94667 13.4788 6.97444 13.4458C7.72417 12.5558 13.8133 3.0398 14.1835 2.58583C14.7951 1.83573 15.7255 1.39844 16.7098 1.39844H77.3859C78.3724 1.39844 79.2979 1.89018 79.8693 2.71801C79.8693 2.71801 87.7976 13.3399 87.3052 13.4902C86.8128 13.6405 6.73063 13.7437 6.92256 13.5083Z"
        fill="white"
        stroke="#333333"
        strokeWidth="1.5"
      />
    </svg>
  );
}

module.exports = FaceToFacePaymentImage;
