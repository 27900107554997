const React = require('react');

function NotebookImage() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 94 57"
    >
      <rect
        x="9.19019"
        y="7.66406"
        width="75.7712"
        height="42.2119"
        className="dx-devsite-home-images-brand-color"
        stroke="white"
        strokeWidth="3"
      />
      <rect
        x="7.85486"
        y="6.05469"
        width="78.4441"
        height="45.1015"
        stroke="#333333"
        strokeWidth="1.5"
      />
      <path
        d="M7.85486 4.4138C7.85486 2.86927 9.10694 1.61719 10.6515 1.61719H83.5164C85.061 1.61719 86.3131 2.86927 86.3131 4.4138V6.20416H7.85486V4.4138Z"
        fill="white"
        stroke="#333333"
        strokeWidth="1.5"
      />
      <path
        d="M1.59485 51.2383C1.59485 54.0019 3.8352 56.2422 6.59881 56.2422H87.569C90.3326 56.2422 92.573 54.0019 92.573 51.2383H1.59485Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.80484 54.8307H87.3642C89.5953 54.8307 91.4827 53.6297 92.1087 51.9766H92.3292L92.3274 52.0881C92.255 54.2744 90.0606 56.0286 87.3642 56.0286H6.80484C4.06275 56.0286 1.83984 54.2144 1.83984 51.9766H2.0604C2.68636 53.6297 4.57374 54.8307 6.80484 54.8307Z"
        fill="#EEEEEE"
        style={{ mixBlendMode: 'multiply' }}
      />
      <path
        d="M1.59485 51.2383C1.59485 54.0019 3.8352 56.2422 6.59882 56.2422H87.569C90.3326 56.2422 92.573 54.0019 92.573 51.2383H1.59485Z"
        stroke="#333333"
        strokeWidth="1.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.4692 51.2383C38.4692 52.1597 39.2167 52.9063 40.1383 52.9063H54.029C54.9509 52.9063 55.6986 52.1597 55.6986 51.2383H38.4692Z"
        stroke="#333333"
        strokeWidth="1.5"
      />
      <ellipse
        cx="47.3088"
        cy="4.12884"
        rx="0.699711"
        ry="0.699153"
        fill="#333333"
      />
      <rect
        x="30.8973"
        y="13.1953"
        width="33.0295"
        height="30.1902"
        fill="white"
        stroke="#333333"
        strokeWidth="1.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.3404 23.6719H30.1085C28.8683 23.6735 27.8629 24.8167 27.86 26.2284V30.5785C27.8619 31.9912 28.8675 33.1358 30.1085 33.1381H64.3404C65.5812 33.1358 66.5865 31.991 66.588 30.5785V26.2284C66.5855 24.8168 65.5804 23.6735 64.3404 23.6719Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.86 16.205C27.86 16.6949 27.86 17.4298 27.86 18.4097C27.8619 19.8224 28.8675 20.967 30.1085 20.9692H64.3404C65.5812 20.967 66.5865 19.8222 66.588 18.4097V14.0595C66.5865 12.647 65.5812 11.5022 64.3404 11.5H30.1085C28.8675 11.5022 27.8619 12.6469 27.86 14.0595V16.205Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.3404 35.9336H30.1085C28.8683 35.9353 27.8629 37.0784 27.86 38.4901V42.8403C27.8619 44.2529 28.8675 45.3976 30.1085 45.3998H64.3404C65.5812 45.3976 66.5865 44.2528 66.588 42.8403V38.4901C66.5855 37.0785 65.5804 35.9353 64.3404 35.9336Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.86 18.3185V16.7227C27.8619 18.1353 28.8675 19.28 30.1085 19.2822H64.3404C65.5812 19.28 66.5865 18.1352 66.588 16.7227V18.3185C66.5865 19.731 65.5812 20.8758 64.3404 20.878H30.1085C28.8675 20.8758 27.8619 19.7312 27.86 18.3185ZM27.86 28.9837V30.5796C27.8619 31.9922 28.8675 33.1369 30.1085 33.1391H64.3404C65.5812 33.1369 66.5865 31.9921 66.588 30.5796V28.9837C66.5865 30.3962 65.5812 31.541 64.3404 31.5432H30.1085C28.8675 31.541 27.8619 30.3964 27.86 28.9837ZM27.86 41.2448V42.8406C27.8619 44.2533 28.8675 45.3979 30.1085 45.4001H64.3404C65.5812 45.3979 66.5865 44.2531 66.588 42.8406V41.2448C66.5865 42.6573 65.5812 43.8021 64.3404 43.8043H30.1085C28.8675 43.8021 27.8619 42.6574 27.86 41.2448Z"
        fill="#EEEEEE"
        style={{ mixBlendMode: 'multiply' }}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.3404 23.6719H30.1085C28.8683 23.6735 27.8629 24.8167 27.86 26.2284V30.5785C27.8619 31.9912 28.8675 33.1358 30.1085 33.1381H64.3404C65.5812 33.1358 66.5865 31.991 66.588 30.5785V26.2284C66.5855 24.8168 65.5804 23.6735 64.3404 23.6719Z"
        stroke="#333333"
        strokeWidth="1.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.86 16.205C27.86 16.6949 27.86 17.4298 27.86 18.4097C27.8619 19.8224 28.8675 20.967 30.1085 20.9692H64.3404C65.5812 20.967 66.5865 19.8222 66.588 18.4097V14.0595C66.5865 12.647 65.5812 11.5022 64.3404 11.5H30.1085C28.8675 11.5022 27.8619 12.6469 27.86 14.0595V16.205Z"
        stroke="#333333"
        strokeWidth="1.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.3404 35.9336H30.1085C28.8683 35.9353 27.8629 37.0784 27.86 38.4901V42.8403C27.8619 44.2529 28.8675 45.3976 30.1085 45.3998H64.3404C65.5812 45.3976 66.5865 44.2528 66.588 42.8403V38.4901C66.5855 37.0785 65.5804 35.9353 64.3404 35.9336Z"
        stroke="#333333"
        strokeWidth="1.5"
      />
      <mask id="path-17-inside-1_20415_96044" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.2712 16.0655C37.2712 17.4263 36.1651 18.5294 34.8007 18.5294C33.4364 18.5294 32.3303 17.4263 32.3303 16.0655C32.3303 14.7047 33.4364 13.6016 34.8007 13.6016C36.1651 13.6016 37.2712 14.7047 37.2712 16.0655ZM37.2712 28.4075C37.2712 29.7683 36.1651 30.8714 34.8007 30.8714C33.4364 30.8714 32.3303 29.7683 32.3303 28.4075C32.3303 27.0468 33.4364 25.9436 34.8007 25.9436C36.1651 25.9436 37.2712 27.0468 37.2712 28.4075ZM34.8007 43.0448C36.1651 43.0448 37.2712 41.9417 37.2712 40.5809C37.2712 39.2201 36.1651 38.117 34.8007 38.117C33.4364 38.117 32.3303 39.2201 32.3303 40.5809C32.3303 41.9417 33.4364 43.0448 34.8007 43.0448Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.2712 16.0655C37.2712 17.4263 36.1651 18.5294 34.8007 18.5294C33.4364 18.5294 32.3303 17.4263 32.3303 16.0655C32.3303 14.7047 33.4364 13.6016 34.8007 13.6016C36.1651 13.6016 37.2712 14.7047 37.2712 16.0655ZM37.2712 28.4075C37.2712 29.7683 36.1651 30.8714 34.8007 30.8714C33.4364 30.8714 32.3303 29.7683 32.3303 28.4075C32.3303 27.0468 33.4364 25.9436 34.8007 25.9436C36.1651 25.9436 37.2712 27.0468 37.2712 28.4075ZM34.8007 43.0448C36.1651 43.0448 37.2712 41.9417 37.2712 40.5809C37.2712 39.2201 36.1651 38.117 34.8007 38.117C33.4364 38.117 32.3303 39.2201 32.3303 40.5809C32.3303 41.9417 33.4364 43.0448 34.8007 43.0448Z"
        className="dx-devsite-home-images-brand-color"
      />
      <path
        d="M34.8007 21.5294C37.8145 21.5294 40.2712 19.0906 40.2712 16.0655H34.2712C34.2712 15.7619 34.5157 15.5294 34.8007 15.5294V21.5294ZM29.3303 16.0655C29.3303 19.0906 31.787 21.5294 34.8007 21.5294V15.5294C35.0858 15.5294 35.3303 15.7619 35.3303 16.0655H29.3303ZM34.8007 10.6016C31.787 10.6016 29.3303 13.0404 29.3303 16.0655H35.3303C35.3303 16.369 35.0858 16.6016 34.8007 16.6016V10.6016ZM40.2712 16.0655C40.2712 13.0404 37.8145 10.6016 34.8007 10.6016V16.6016C34.5157 16.6016 34.2712 16.369 34.2712 16.0655H40.2712ZM34.8007 33.8714C37.8145 33.8714 40.2712 31.4326 40.2712 28.4075H34.2712C34.2712 28.104 34.5157 27.8714 34.8007 27.8714V33.8714ZM29.3303 28.4075C29.3303 31.4326 31.787 33.8714 34.8007 33.8714V27.8714C35.0858 27.8714 35.3303 28.104 35.3303 28.4075H29.3303ZM34.8007 22.9436C31.787 22.9436 29.3303 25.3824 29.3303 28.4075H35.3303C35.3303 28.7111 35.0858 28.9436 34.8007 28.9436V22.9436ZM40.2712 28.4075C40.2712 25.3824 37.8145 22.9436 34.8007 22.9436V28.9436C34.5157 28.9436 34.2712 28.7111 34.2712 28.4075H40.2712ZM34.2712 40.5809C34.2712 40.2773 34.5157 40.0448 34.8007 40.0448V46.0448C37.8145 46.0448 40.2712 43.606 40.2712 40.5809H34.2712ZM34.8007 41.117C34.5157 41.117 34.2712 40.8844 34.2712 40.5809H40.2712C40.2712 37.5558 37.8145 35.117 34.8007 35.117V41.117ZM35.3303 40.5809C35.3303 40.8844 35.0858 41.117 34.8007 41.117V35.117C31.787 35.117 29.3303 37.5558 29.3303 40.5809H35.3303ZM34.8007 40.0448C35.0858 40.0448 35.3303 40.2773 35.3303 40.5809H29.3303C29.3303 43.606 31.787 46.0448 34.8007 46.0448V40.0448Z"
        fill="white"
        mask="url(#path-17-inside-1_20415_96044)"
      />
      <ellipse
        cx="34.8007"
        cy="16.0655"
        rx="2.47042"
        ry="2.46391"
        stroke="#333333"
        strokeWidth="1.5"
      />
      <ellipse
        cx="34.8007"
        cy="28.4092"
        rx="2.47042"
        ry="2.46391"
        stroke="#333333"
        strokeWidth="1.5"
      />
      <ellipse
        cx="34.8007"
        cy="40.5811"
        rx="2.47042"
        ry="2.46391"
        stroke="#333333"
        strokeWidth="1.5"
      />
      <path
        d="M44.621 16.2347H62.6649"
        stroke="#333333"
        strokeWidth="1.5"
      />
      <path
        d="M44.621 28.4066H62.6649"
        stroke="#333333"
        strokeWidth="1.5"
      />
      <path
        d="M44.621 40.5824H62.6649"
        stroke="#333333"
        strokeWidth="1.5"
      />
      <path
        d="M42.834 16.2347H39.4699"
        stroke="#333333"
        strokeWidth="1.5"
      />
      <path
        d="M42.834 28.4066H39.4699"
        stroke="#333333"
        strokeWidth="1.5"
      />
      <path
        d="M42.834 40.5824H39.4699"
        stroke="#333333"
        strokeWidth="1.5"
      />
    </svg>
  );
}

module.exports = NotebookImage;
