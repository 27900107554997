/* eslint-disable max-len */
const React = require('react');
const PropTypes = require('prop-types');
const HomeImages = require('../../images');
const ProgrammingLanguage = require('./ProgrammingLanguage');
const { Title } = require('@andes-landings/title');
const { useI18n } = require('nordic/i18n');

const ProgrammingLangSupported = ({ language }) => {
  const { HomeJAVAImage, HomeRubyImage, HomePhytonImage, HomePHPImage, HomeNodeJSImage, HomeNetImage } = HomeImages;
  const { i18n } = useI18n();

  const langs = [
    [
      { image: <HomeJAVAImage />, language: i18n.gettext('Java'), link: `/developers/${language}/docs/sdks-library/server-side`, tracking: 'HOME_SDKS_LINK_JAVA', label: 'Java' },
      { image: <HomeRubyImage />, language: i18n.gettext('Ruby'), link: `/developers/${language}/docs/sdks-library/server-side`, tracking: 'HOME_SDKS_LINK_RUBY', label: 'Ruby' },
      { image: <HomePhytonImage />, language: i18n.gettext('Python'), link: `/developers/${language}/docs/sdks-library/server-side`, tracking: 'HOME_SDKS_LINK_PHYTON', label: 'Python' },
    ],
    [
      { image: <HomePHPImage />, language: i18n.gettext('PHP'), link: `/developers/${language}/docs/sdks-library/server-side`, tracking: 'HOME_SDKS_LINK_PHP', label: 'Php' },
      { image: <HomeNodeJSImage />, language: i18n.gettext('Node.js'), link: `/developers/${language}/docs/sdks-library/server-side`, tracking: 'HOME_SDKS_LINK_NODE', label: 'Node JS' },
      { image: <HomeNetImage />, language: i18n.gettext('.Net'), link: `/developers/${language}/docs/sdks-library/server-side`, tracking: 'HOME_SDKS_LINK_NET', label: 'Dot net' },
    ],
  ];
  // TODO: Otra opción seria enviar 'HOME_SDKS_LINK' y como tag JAVA,PHP, etc.
  return (
    <section className="programm-lang-support">
      <Title
        description={i18n.gettext('Facilita el desarrollo con nuestras bibliotecas SDK')}
        title={i18n.gettext('Gana tiempo al hacer tu integración')}
        heading="h2"
      />
      <div className="programm-lang-support__container">
        {langs.map((lang) => (<div className="programm-lang-support__subcontainer">
          {lang.map((l) => (<ProgrammingLanguage
            image={l.image}
            language={l.language}
            link={l.link}
            tracking={l.tracking}
            label={l.label}
          />))}
        </div>))}
      </div>
    </section>
  );
};

ProgrammingLangSupported.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func.isRequired,
  }),
};

ProgrammingLangSupported.defaultProps = {
  i18n: { gettext: () => { } },
};

module.exports = ProgrammingLangSupported;
