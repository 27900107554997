/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable max-len */
const React = require('react');
const PropTypes = require('prop-types');
const PaymentSolutionsCard = require('./PaymentSolutionsCard');
const HomeImages = require('../../images');
const { getEventTracker } = require('../../../../../utils/event-tracker');
const { Title } = require('@andes-landings/title');
const { useI18n } = require('nordic/i18n');

const HomePaymentSolutions = ({ lang, platform }) => {
  const {
    OnlinePaymentImage,
    FaceToFacePaymentImage,
    WalletImage,
    NotebookImage,
    SecurityImage,
  } = HomeImages;

  const { i18n } = useI18n();

  const sitesAvailable = ['MLA', 'MLM', 'MLB', 'MLC', 'MLU'];
  const notAllowed = ['MCO', 'MPE'];

  const Tracker = getEventTracker();

  return (
    <div className="payment-solutions">
      <div>
        <Title
          description={i18n.gettext('Mercado Pago tiene diferentes medios de pago que se adecúan a tu negocio.')}
          title={i18n.gettext('Conoce más sobre las soluciones de Mercado Pago.')}
          heading="h2"
        />
        <div className="payment-solutions__cards-container">
          <PaymentSolutionsCard
            image={<OnlinePaymentImage />}
            title={i18n.gettext('Pagos online')}
            description={i18n.gettext(
              'Soluciones para quienes venden a través del sitio, por WhatsApp o por redes sociales. Integra directamente o con diferentes plugins o plataformas.',
            )}
            link={`/developers/${lang}/docs#online-payments`}
            linkDescription={i18n.gettext('Saber más')}
            isRel={false}
            cardFormat
            titleLink={i18n.gettext('Saber más sobre pagos online')}
            trackClickData="HOME_PAYMENTSOLUTION_BUTTON_ONLINE"
          />
          {!notAllowed.includes(platform?.siteId) && <PaymentSolutionsCard
            image={<FaceToFacePaymentImage />}
            title={i18n.gettext('Pagos presenciales')}
            description={
              sitesAvailable.includes(platform?.siteId)
                ? i18n.gettext(
                  'Soluciones para quienes venden desde casa, en la calle, en el auto o desde una tienda física. Acepta pagos con las tarjetas más usadas o código QR.',
                ) : i18n.gettext(
                  'Soluciones para quienes venden en casa, en la calle, en el coche o en una tienda física. Acepta pagos con las tarjetas más utilizadas.',
                )
            }
            link={`/developers/${lang}/docs#inperson-payments`}
            linkDescription={i18n.gettext('Saber más')}
            isRel={false}
            cardFormat
            titleLink={i18n.gettext('Saber más sobre pagos presenciales')}
            trackClickData="HOME_PAYMENTSOLUTION_BUTTON_PRESENCIAL"
          />}
        </div>
      </div>
      <div>
        <Title
          description={i18n.gettext(
            'Conoce las ventajas de integrar nuestras soluciones a tu negocio.',
          )}
          title={i18n.gettext('¿Por qué usar Mercado Pago?')}
          heading="h2"
        />
        <div className="payment-solutions__cards-container payment-solutions__cards-container--security">
          <PaymentSolutionsCard
            image={<SecurityImage />}
            title={i18n.gettext('Seguridad')}
            description={
              (<span>
                {i18n.gettext(
                  'Contamos con un sistema antifraude robusto y soluciones compatibles con el ',
                )}
                <a
                  className="payment-solutions__security-link"
                  href={`/developers/${lang}/docs/security/pci`}
                  onClick={() => { Tracker.send('mpdevelopers/click_home_usemp_link_pci'); }}
                >
                  {i18n.gettext('PCI DSS')}.
                </a>
              </span>)
            }
            isRel={false}

          />
          <PaymentSolutionsCard
            image={<WalletImage />}
            title={i18n.gettext('Más ventas')}
            description={i18n.gettext(
              'Ofrece las opciones de pago más usadas en el mercado y no pierdas ventas.',
            )}
            isRel={false}
          />
          <PaymentSolutionsCard
            image={<NotebookImage />}
            title={i18n.gettext('Facilidad')}
            description={i18n.gettext(
              'Gestiona todas tus integraciones y aplicaciones en un solo lugar.',
            )}
            isRel={false}
          />
        </div>
      </div>
    </div>
  );
};
HomePaymentSolutions.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func.isRequired,
  }),
  siteId: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired,
};

HomePaymentSolutions.defaultProps = {
  i18n: { gettext: () => { } },
};

module.exports = HomePaymentSolutions;
