/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable max-len */
const React = require('react');
const PropTypes = require('prop-types');
const Card = require('@andes/card');
const { Title } = require('@andes-landings/title');
const { CardContent } = require('@andes/card');
const { useI18n } = require('nordic/i18n');
const HomeImages = require('../../images');
const { changeDomainUrl } = require('../../../Documentation/components/PartnervVisibility/components/utils');


const HomeHelp = ({ platform, lang }) => {
  const { HomeHelpRebranding, HomeHelpDiscordRebranding, HomeHelpPartnersRebranding, HomeHelpContactRebranding, HomeHelpStatusRebranding } = HomeImages;
  const { i18n } = useI18n();
  const siteIdAvailables = ['MLB'];
  const allowedSiteIds = ['MLA', 'MLM', 'MLB'];

  const { siteId } = platform;

  return (
    <>
      <div className="home-help">
        <Title
          description=""
          title={i18n.gettext('¿Necesitas ayuda?')}
          heading="h2"
        />
        <div className="cards-home-help">
          <a href={`https://mercadopago.com/developers/${lang}/support/center`} target="_blank">
            <Card className="card" shadow="outline" paddingSize="0">
              <CardContent className="content">
                <div className="card-help-details">
                  <div className="card-help-details-icon">
                    <HomeHelpRebranding />
                  </div>
                  <div>
                    <h5>{i18n.gettext('Soporte técnico oficial')}</h5>
                    <span>{i18n.gettext('Sácate las dudas acerca de las integraciones, cuentas y otros asuntos técnicos con nuestro equipo especializado.')}</span>
                  </div>
                </div>
              </CardContent>
            </Card>
          </a>
          <a href="https://discord.com/invite/yth5bMKhdn" target="_blank">
            <Card className="card" shadow="outline" paddingSize="0">
              <CardContent className="content">
                <div className="card-help-details">
                  <div className="card-help-details-icon">
                    <HomeHelpDiscordRebranding />
                  </div>
                  <div>
                    <h5>{i18n.gettext('Comunidad Discord')}</h5>
                    <span>{i18n.gettext('Intercambia conocimiento con otros profesionales de desarrollo en nuestro canal oficial en Discord.')}</span>
                  </div>
                </div>
              </CardContent>
            </Card>
          </a>
          {allowedSiteIds.includes(platform.siteId) && (
            <a href={changeDomainUrl(siteId)} target="_blank">
              <Card className="card" shadow="outline" paddingSize="0">
                <CardContent className="content">
                  <div className="card-help-details">
                    <div className="card-help-details-icon">
                      <HomeHelpPartnersRebranding />
                    </div>
                    <div>
                      <h5>{i18n.gettext('Centro de Partners')}</h5>
                      <span>{i18n.gettext('Contrata a un partner que te ayude a integrar nuestras soluciones de manera segura y ágil.')}</span>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </a>
          )}
          {siteIdAvailables.includes(platform.siteId) && (
            <a href="https://www.mercadopago.com.br/quero-usar/" target="_blank">
              <Card className="card" shadow="outline" paddingSize="0">
                <CardContent className="content">
                  <div className="card-help-details">
                    <div className="card-help-details-icon">
                      <HomeHelpContactRebranding />
                    </div>
                    <div>
                      <h5>{i18n.gettext('Contacto Comercial')}</h5>
                      <span>{i18n.gettext('Contacta con nuestro equipo para conocer las ventajas de utilizar nuestras soluciones en tu negocio.')}</span>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </a>
          )}
          <a href="https://status.mercadopago.com/" target="_blank">
            <Card className="card" shadow="outline" paddingSize="0">
              <CardContent className="content">
                <div className="card-help-details">
                  <div className="card-help-details-icon">
                    <HomeHelpStatusRebranding />
                  </div>
                  <idv>
                    <h5>{i18n.gettext('Mercado Pago Status')}</h5>
                    <span>{i18n.gettext('Haz el seguimiento en tiempo real si las APIs y otros servicios de Mercado Pago están operando normalmente.')}</span>
                  </idv>
                </div>
              </CardContent>
            </Card>
          </a>
        </div>
      </div>
    </>
  );
};
HomeHelp.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func.isRequired,
  }),
  platform: PropTypes.shape({
    siteId: PropTypes.string.isRequired,
  }).isRequired,
  lang: PropTypes.string.isRequired,
};

HomeHelp.defaultProps = {
  i18n: { gettext: () => { } },
};

module.exports = HomeHelp;
