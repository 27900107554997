const React = require('react');

const HomeHelpDiscordRebranding = () => (
  <svg width="33" height="25" viewBox="0 0 33 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path
        d="M21.5919 4.64683C19.956 3.89788 18.229 3.36655 16.455 3.06641C16.2123 3.50036 15.9926 3.94685 15.797 4.40399C13.9074 4.11924 11.9857 4.11924 10.0961 4.40399C9.90039 3.94689 9.68075 3.50042 9.4381 3.06641C7.66298 3.36908 5.93485 3.90167 4.29731 4.65075C1.04637 9.46057 0.165096 14.1509 0.605735 18.7747C2.50956 20.1813 4.64049 21.2511 6.90589 21.9375C7.41599 21.2514 7.86737 20.5236 8.25522 19.7617C7.51854 19.4866 6.80751 19.1471 6.13037 18.7473C6.30858 18.618 6.48288 18.4848 6.6513 18.3556C8.62164 19.2822 10.7722 19.7626 12.9495 19.7626C15.1268 19.7626 17.2774 19.2822 19.2477 18.3556C19.4181 18.4946 19.5924 18.6278 19.7686 18.7473C19.0902 19.1478 18.3778 19.4879 17.6399 19.7637C18.0272 20.5252 18.4786 21.2524 18.9892 21.9375C21.2565 21.2538 23.3891 20.1846 25.2933 18.7767C25.8103 13.4146 24.41 8.76729 21.5919 4.64683ZM8.81337 15.9311C7.58545 15.9311 6.57101 14.8168 6.57101 13.4459C6.57101 12.075 7.5502 10.9509 8.80945 10.9509C10.0687 10.9509 11.0753 12.075 11.0538 13.4459C11.0322 14.8168 10.0648 15.9311 8.81337 15.9311ZM17.0856 15.9311C15.8558 15.9311 14.8452 14.8168 14.8452 13.4459C14.8452 12.075 15.8244 10.9509 17.0856 10.9509C18.3468 10.9509 19.3456 12.075 19.3241 13.4459C19.3025 14.8168 18.337 15.9311 17.0856 15.9311Z"
        fill="#3483FA" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="32" height="24.253" fill="white" transform="translate(0.5 0.375)" />
      </clipPath>
    </defs>
  </svg>
);

module.exports = HomeHelpDiscordRebranding;
