const React = require('react');

const HomeHelpContactRebranding = () => (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.34373 9.19035H18.3234V7.69035H6.34373V9.19035Z" fill="#3483FA" />
    <path d="M6.34373 12.9522H13.0212V11.4522H6.34373V12.9522Z" fill="#3483FA" />
    <path fillRule="evenodd" clipRule="evenodd" d="M4.36919 19.6904C3.29223 19.6904 2.41919 18.8173 2.41919 17.7404V4.93535C2.41919 3.8584 3.29223 2.98535 4.36919 2.98535H20.1732C21.2501 2.98535 22.1232 3.8584 22.1232 4.93535V17.7404C22.1232 18.8173 21.2501 19.6904 20.1732 19.6904H19.1192V23.3421L13.644 19.6904H4.36919ZM17.6192 18.1904H20.1732C20.4217 18.1904 20.6232 17.9889 20.6232 17.7404V4.93535C20.6232 4.68682 20.4217 4.48535 20.1732 4.48535H4.36919C4.12066 4.48535 3.91919 4.68682 3.91919 4.93535V17.7404C3.91919 17.9889 4.12066 18.1904 4.36919 18.1904H14.0984L17.6192 20.5386V18.1904Z" fill="#3483FA" />
  </svg>
);

module.exports = HomeHelpContactRebranding;
