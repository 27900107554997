/* eslint-disable nordic/no-img-tags */
/* eslint-disable react/jsx-wrap-multilines */
const React = require('react');
const { getEventTracker } = require('../../../../../utils/event-tracker');
const { Background } = require('@andes-landings/background');
const { Image } = require('@andes/common');
const { Hero } = require('@andes-landings/hero');
const { useI18n } = require('nordic/i18n');


const HomeHero = ({ brand, language, assetsPath }) => {
  const { i18n } = useI18n();
  const Tracker = getEventTracker();

  const getImageSrc = () => {
    return `${assetsPath}images/${language === 'pt' ? 'imgHeroHomePT.png' : 'imgHeroHomeES.png'}`;
  };

  return (
    <Background
      className="home-hero"
      component="section"
      curves={3}
      type="hero"
    >
      <div className="home-hero-container">
        <Hero
          actions={{
            primary: {
              children: i18n.gettext('Explorar integraciones'),
              onClick: () => {
                Tracker.send('mpdevelopers/click_home_hero_button');
                window.location.href = `/developers/${language}/docs`;
              },
            },
          }}
          className={`${brand !== 'yellowblue-light' ? 'home-hero-white-text' : ''}`}
          img={
            <Image
              alt={i18n.gettext('DEVELOPERS')}
              src={getImageSrc()}
            />
          }
          title={{
            description:
              i18n.gettext('Ofrecemos soluciones inteligentes, ventajas competitivas y una comunidad para acompañarte.'),
            title: i18n.gettext('Simplifica tus cobros con integraciones a medida'),
            volanta: i18n.gettext('DEVELOPERS'),
          }}
        />
      </div>
    </Background>
  );
};


module.exports = HomeHero;
