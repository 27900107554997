const React = require('react');

function WalletImage() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 101 101"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.5171 52.7122L23.8325 82.3967C22.8827 83.3465 21.3319 83.3359 20.369 82.3731L2.52077 64.5248C1.5579 63.5619 1.54733 62.0111 2.49717 61.0613L32.1817 31.3768C33.1315 30.4269 34.6823 30.4375 35.6452 31.4004L53.4935 49.2487C54.4563 50.2115 54.4669 51.7623 53.5171 52.7122Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.3109 81.3164C20.1001 82.1056 21.7454 81.7443 23.0137 80.5151L23.0729 80.4569L51.5874 51.9424C52.8435 50.6862 53.2356 49.0344 52.4823 48.2188L52.4462 48.1811L53.5021 49.2371C54.4813 50.2163 54.2408 52.0443 52.965 53.3201L24.4505 81.8346C23.1747 83.1104 21.3461 83.3515 20.3669 82.3724L19.3109 81.3164Z"
        fill="#EEEEEE"
        style={{ mixBlendMode: 'multiply' }}
      />
      <path
        d="M53.5171 52.7122L23.8325 82.3967C22.8827 83.3465 21.3319 83.3359 20.369 82.3731L2.52077 64.5248C1.5579 63.5619 1.54733 62.0111 2.49717 61.0613L32.1817 31.3768C33.1315 30.4269 34.6823 30.4375 35.6452 31.4004L53.4935 49.2487C54.4563 50.2115 54.4669 51.7623 53.5171 52.7122"
        stroke="#333333"
        strokeWidth="1.5"
      />
      <rect
        x="19.1459"
        y="73.9492"
        width="7.51668"
        height="5.13597"
        rx="2"
        transform="rotate(-45 19.1459 73.9492)"
        stroke="#333333"
        strokeWidth="1.5"
      />
      <path
        d="M8.37771 68.2596L6.29393 66.1758L37.297 35.1727L39.3808 37.2565L8.37771 68.2596Z"
        className="dx-devsite-home-images-brand-color"
        stroke="white"
        strokeWidth="3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.297 33.0514L4.17261 66.1758L8.37771 70.3809L41.5021 37.2565L37.297 33.0514Z"
        stroke="#333333"
        strokeWidth="1.5"
      />
      <path
        d="M15.7712 70.6855L28.7563 57.7005"
        stroke="#333333"
        strokeWidth="1.5"
      />
      <path
        d="M30.7737 55.6822L34.0679 52.388"
        stroke="#333333"
        strokeWidth="1.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.1919 29.4141H84.8798C89.0583 29.4141 92.4457 32.8014 92.4457 36.9799V66.5556C92.4457 70.7341 89.0583 74.1215 84.8798 74.1215H37.1919C36.4322 74.1215 35.8163 73.5056 35.8163 72.7459V30.7897C35.8163 30.0299 36.4322 29.4141 37.1919 29.4141Z"
        fill="white"
        stroke="#333333"
        strokeWidth="1.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.4071 48.1244L36.9791 45.9415C36.3358 45.3632 36.2847 44.3733 36.8646 43.7303L59.0041 19.1793C59.584 18.5363 60.5753 18.4836 61.2185 19.0619L75.9652 32.3195C76.6081 32.8976 76.6592 33.8874 76.0793 34.5305L53.9398 59.0814C53.3599 59.7245 52.3687 59.7772 51.7257 59.1992L41.0564 49.6072L39.4071 48.1244Z"
        fill="white"
        stroke="#333333"
        strokeWidth="1.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.654 40.9472L43.0496 37.6639C42.6245 36.794 42.9865 35.7454 43.8579 35.3211L77.1273 19.1258C77.9988 18.7016 79.0496 19.0625 79.4747 19.9324L89.2195 39.8738C89.6444 40.7432 89.2824 41.7918 88.4109 42.2161L55.1415 58.4114C54.2701 58.8356 53.2193 58.4747 52.7944 57.6053L45.744 43.1776L44.654 40.9472Z"
        fill="white"
        stroke="#333333"
        strokeWidth="1.5"
      />
      <path
        d="M37.3163 81.648V36.7773H88.3513C91.997 36.7773 94.9525 39.7328 94.9525 43.3785V75.0468C94.9525 78.6926 91.997 81.648 88.3513 81.648H37.3163Z"
        className="dx-devsite-home-images-brand-color"
        stroke="white"
        strokeWidth="3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.2892 35.2773H88.3513C92.8254 35.2773 96.4525 38.9044 96.4525 43.3785V75.0468C96.4525 79.521 92.8254 83.148 88.3513 83.148H37.2892C36.4757 83.148 35.8163 82.4886 35.8163 81.6751V36.7503C35.8163 35.9368 36.4757 35.2773 37.2892 35.2773Z"
        stroke="#333333"
        strokeWidth="1.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.4866 59.688C50.4866 53.8585 55.2123 49.1328 61.0418 49.1328H99.9711V70.2432H61.0418C55.2123 70.2432 50.4866 65.5175 50.4866 59.688Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.6309 57.9414C50.536 58.5105 50.4866 59.095 50.4866 59.691C50.4866 65.5205 55.2123 70.2462 61.0418 70.2462H99.9711V66.747H61.0418C55.8083 66.747 51.4645 62.9382 50.6309 57.9414Z"
        fill="#EEEEEE"
        style={{ mixBlendMode: 'multiply' }}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.4866 59.688C50.4866 53.8585 55.2123 49.1328 61.0418 49.1328H99.9711V70.2432H61.0418C55.2123 70.2432 50.4866 65.5175 50.4866 59.688V59.688Z"
        stroke="#333333"
        strokeWidth="1.5"
      />
      <circle
        cx="61.9744"
        cy="59.6893"
        r="4.86116"
        fill="white"
        stroke="#333333"
        strokeWidth="1.5"
      />
      <ellipse
        cx="75.4594"
        cy="27.1639"
        rx="4.19263"
        ry="4.1341"
        transform="rotate(-25.4676 75.4594 27.1639)"
        stroke="#333333"
      />
      <path
        d="M74.6716 25.3673L74.3602 24.7135"
        stroke="#333333"
      />
      <path
        d="M76.6179 29.4533L76.3065 28.7995"
        stroke="#333333"
      />
      <path
        d="M75.9505 25.414C75.6444 25.1528 75.2132 25.1077 74.6284 25.3862C74.016 25.6779 73.7234 26.236 73.9617 26.7363C74.2571 27.3564 74.6881 27.3052 75.3223 27.1847C75.3625 27.1774 75.4025 27.1701 75.4424 27.1629L75.5218 27.149C76.0364 27.0607 76.6646 26.9133 76.9517 27.3871C77.2388 27.8609 77.051 28.4943 76.2414 28.8776C75.669 29.1486 75.0593 29.1128 74.7727 28.7341"
        stroke="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.75623 32.213C2.75623 32.4891 2.98008 32.713 3.25623 32.713H7.43114C7.70728 32.713 7.93114 32.4891 7.93114 32.213V27.9883C7.93114 27.7121 7.70728 27.4883 7.43114 27.4883H3.25623C2.98008 27.4883 2.75623 27.7121 2.75623 27.9883V32.213Z"
        stroke="#333333"
        strokeWidth="0.8"
      />
      <path
        d="M4.86367 25.4453H0.896123C0.830456 25.4453 0.777222 25.4991 0.777222 25.5654V29.5711"
        stroke="#333333"
        strokeWidth="0.8"
      />
      <path
        d="M4.86367 42.5508H0.896123C0.830456 42.5508 0.777222 42.497 0.777222 42.4307V38.425"
        stroke="#333333"
        strokeWidth="0.8"
      />
      <path
        d="M13.28 25.4453H17.2476C17.3132 25.4453 17.3665 25.4991 17.3665 25.5654V29.5711"
        stroke="#333333"
        strokeWidth="0.8"
      />
      <path
        d="M13.28 42.5508H17.2476C17.3132 42.5508 17.3665 42.497 17.3665 42.4307V38.425"
        stroke="#333333"
        strokeWidth="0.8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3978 32.213C10.3978 32.4891 10.6217 32.713 10.8978 32.713H15.0727C15.3489 32.713 15.5727 32.4891 15.5727 32.213V27.9883C15.5727 27.7121 15.3489 27.4883 15.0727 27.4883H10.8978C10.6217 27.4883 10.3978 27.7121 10.3978 27.9883V32.213Z"
        stroke="#333333"
        strokeWidth="0.8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.75623 39.9864C2.75623 40.2626 2.98008 40.4864 3.25623 40.4864H7.43114C7.70728 40.4864 7.93114 40.2626 7.93114 39.9864V35.7617C7.93114 35.4856 7.70728 35.2617 7.43114 35.2617H3.25623C2.98008 35.2617 2.75623 35.4856 2.75623 35.7617V39.9864Z"
        stroke="#333333"
        strokeWidth="0.8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9871 30.632C13.9871 30.8974 13.7719 31.1126 13.5066 31.1126V31.1126H12.4836C12.2075 31.1126 11.9836 30.8887 11.9836 30.6126V29.5898C11.9836 29.3137 12.2075 29.0898 12.4836 29.0898H13.4871C13.7632 29.0898 13.9871 29.3137 13.9871 29.5898V30.632Z"
        stroke="#333333"
        strokeWidth="0.8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.34548 30.632C6.34548 30.8974 6.13034 31.1126 5.86496 31.1126V31.1126H4.84204C4.5659 31.1126 4.34204 30.8887 4.34204 30.6126V29.5898C4.34204 29.3137 4.5659 29.0898 4.84204 29.0898H5.84548C6.12162 29.0898 6.34548 29.3137 6.34548 29.5898V30.632Z"
        stroke="#333333"
        strokeWidth="0.8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.34548 38.4055C6.34548 38.6709 6.13034 38.886 5.86496 38.886V38.886H4.84204C4.5659 38.886 4.34204 38.6621 4.34204 38.386V37.3633C4.34204 37.0871 4.5659 36.8633 4.84204 36.8633H5.84548C6.12162 36.8633 6.34548 37.0871 6.34548 37.3633V38.4055Z"
        stroke="#333333"
        strokeWidth="0.8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9853 37.3741C12.9853 37.6502 12.7614 37.8741 12.4853 37.8741H12.3647H10.8978C10.6217 37.8741 10.3978 37.6502 10.3978 37.3741V35.7617C10.3978 35.4856 10.6217 35.2617 10.8978 35.2617H12.4853C12.7614 35.2617 12.9853 35.4856 12.9853 35.7617V37.3741Z"
        stroke="#333333"
        strokeWidth="0.8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5728 39.9873C15.5728 40.2635 15.3489 40.4873 15.0728 40.4873H14.9522H13.4854C13.2092 40.4873 12.9854 40.2635 12.9854 39.9873V38.375C12.9854 38.0989 13.2092 37.875 13.4854 37.875H15.0728C15.3489 37.875 15.5728 38.0989 15.5728 38.375V39.9873Z"
        stroke="#333333"
        strokeWidth="0.8"
      />
    </svg>
  );
}

module.exports = WalletImage;
