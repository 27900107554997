const React = require('react');

const HomeHelpRebranding = () => (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.715 13.568C11.715 12.3644 12.5756 11.7524 13.3842 11.1774L13.389 11.174L13.4297 11.1455C14.1339 10.6529 14.775 10.2044 14.775 9.392C14.775 8.6 14.163 7.916 12.867 7.916C11.643 7.916 10.815 8.456 10.185 9.284L9.141 8.132C10.023 6.998 11.373 6.314 13.047 6.314C15.279 6.314 16.629 7.538 16.629 9.122C16.629 10.616 15.603 11.354 14.667 12.002L14.5806 12.0646C13.8946 12.5606 13.281 13.0044 13.281 13.73C13.281 14.018 13.461 14.36 13.713 14.576L12.381 15.08C11.931 14.648 11.715 14.144 11.715 13.568Z" fill="#009EE3" />
    <path d="M11.769 17.564C11.769 16.952 12.273 16.448 12.885 16.448C13.497 16.448 14.019 16.952 14.019 17.564C14.019 18.176 13.497 18.698 12.885 18.698C12.273 18.698 11.769 18.176 11.769 17.564Z" fill="#009EE3" />
    <path fillRule="evenodd" clipRule="evenodd" d="M12.75 23.75C18.9632 23.75 24 18.7132 24 12.5C24 6.2868 18.9632 1.25 12.75 1.25C6.5368 1.25 1.5 6.2868 1.5 12.5C1.5 18.7132 6.5368 23.75 12.75 23.75ZM12.75 22.25C7.36522 22.25 3 17.8848 3 12.5C3 7.11522 7.36522 2.75 12.75 2.75C18.1348 2.75 22.5 7.11522 22.5 12.5C22.5 17.8848 18.1348 22.25 12.75 22.25Z" fill="#009EE3" />
  </svg>
);

module.exports = HomeHelpRebranding;
