/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
const React = require('react');
const injectI18n = require('nordic/i18n/injectI18n');
const SideBarItem = require('./SidebarItem');
const MenuUtils = require('../../../utils/menu');
const { sanitizePath } = require('../../common/URLSanitizer');
const Collapse = require('./images/Collapse');
const Search = require('./images/Search');
const Close = require('./images/Close');
const SearchBar = require('../SearchBar');
const { useRef, useEffect, useState } = React;
const SidebarComponent = ({
  actualPath,
  i18n,
  setHtmlContent,
  lang,
  deviceType,
  history,
  menuItems,
  activeItem,
  setActiveItem,
  siteId,
}) => {
  const activePath = actualPath.replace(/\/developers\/(es|pt|en)\//, '/');
  const referencePage = actualPath.includes('docs') ? null : 'references';
  const firstLevelContainer = useRef(null);
  const [showMenu, setShowMenu] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  let actualMenu = menuItems;
  if (!referencePage) {
    actualMenu = actualMenu.filter((item) => {
      let matchUrl = false;
      if (item.url.match(/\/docs\/(.*?)\//) && actualPath.match(/\/docs\/(.*?)\//)) {
        matchUrl = item.url.match(/\/docs\/(.*?)\//)[0] === actualPath.match(/\/docs\/(.*?)\//)[0];
      }
      return matchUrl;
    });
    actualMenu = actualMenu[0]?.childs;
  }
  actualMenu = actualMenu || menuItems;
  useEffect(() => {
    setIsMobile(deviceType === 'mobile' && (typeof window !== 'undefined' && window.innerWidth < 768));
  }, []);
  useEffect(() => {
    history.listen((destination, action) => {
      if (action === 'POP') {
        let item = MenuUtils.getItemFromURL(
          sanitizePath(destination.pathname),
          siteId,
          lang,
          referencePage,
        );
        item = item?.lvl < 3 ? item : item?.parentItem;
        if (item) {
          setActiveItem(item);
        }
      }
    });
  }, [history]);
  const onNavbarClick = () => {
    setShowMenu(!showMenu);
  };
  const SidebarNav = () => (
    <>
      <div className="sidebar-mobile-navbar">
        <div
          className="sidebar-mobile-navbar__title"
          role="button"
          tabIndex={0}
        >
          {isMobile && (
            <>
              <p
                tabIndex={0}
                role="button"
                onClick={onNavbarClick}
                onKeyDown={onNavbarClick}
              >{activeItem.title}
              </p>
              <div
                role="button"
                tabIndex={0}
                onClick={onNavbarClick}
                onKeyDown={onNavbarClick}
                className={`collapse-image ${
                  showMenu ? 'collapse-image--collapsed' : ''
                }`}
              >
                <Collapse />
              </div>
              <div className="search-control-divisor" />
              <div
                onClick={() => setShowInput(true)}
                onKeyDown={() => setShowInput(true)}
                role="button"
                tabIndex={0}
              >
                <Search />
              </div>
            </>
          )}
        </div>
        <div className="sidebar-mobile-navbar__searchbar" style={{ display: showInput ? 'block' : 'none' }}>
          {/* holder for sarchbar portal */}
          <div
            className="close-icon-container"
            onClick={() => setShowInput(false)}
            role="button"
            tabIndex={0}
          >
            <Close />
          </div>
        </div>
      </div>
      <div className="sidebar-desktop-navbar">
        {/* holder for sarchbar portal */}
      </div>
    </>
  );
  return (
    <>
      {isMobile && SidebarNav()}
      <div
        className={`sidebar-container slideout-menu slideout-menu-left slidemobile-menu ${
          showMenu ? 'slideout-menu--open' : ''
        }`}
      >
        <nav className="nav-container">
          {!isMobile && SidebarNav()}
          <ul className="ul-container" ref={firstLevelContainer}>
            {referencePage && (
              <p className="api-group-title">
                {`${i18n.gettext('LISTA DE API')}`}
              </p>
            )}
            {actualMenu.filter((item) => !item.hidden).map((item) => (
              <SideBarItem
                key={item.key}
                active={activePath}
                item={item}
                i18n={i18n}
                setHtmlContent={setHtmlContent}
                lang={lang}
                activeItem={activeItem}
                setActiveItem={setActiveItem}
                setShowMenu={setShowMenu}
              >
                {item.logo}
              </SideBarItem>
            ))}
            {isMobile && (
              <div>
                <SideBarItem
                  key="devprogram"
                  item={{
                    url: `/developers/${lang}/developer-program`,
                    title: i18n.gettext('<dev>program'),
                    lvl: 1,
                  }}
                  active={activePath}
                  i18n={i18n}
                  lang={lang}
                  setShowMenu={setShowMenu}
                />
                <SideBarItem
                  key="api-reference"
                  item={{
                    url: `/developers/${lang}/reference`,
                    title: i18n.gettext('Referencia de API'),
                    lvl: 1,
                  }}
                  active={activePath}
                  i18n={i18n}
                  lang={lang}
                  setShowMenu={setShowMenu}
                />
                <SideBarItem
                  key="soporte"
                  item={{
                    url: `/developers/${lang}/support`,
                    title: i18n.gettext('Soporte'),
                    lvl: 1,
                  }}
                  active={activePath}
                  i18n={i18n}
                  lang={lang}
                  setShowMenu={setShowMenu}
                />
              </div>
            )}
          </ul>
        </nav>
      </div>

      <div>
        <SearchBar
          lang={lang}
          siteId={siteId}
          layout="fixed"
          maxBoxVH="80"
          isMobile={isMobile}
          portal={isMobile ? '.sidebar-mobile-navbar__searchbar' : '.sidebar-desktop-navbar'}
        />
      </div>
    </>
  );
};
module.exports = injectI18n(SidebarComponent);
